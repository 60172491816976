import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import 'assets/stylesheets/application.scss';

import Layout from 'e84-website-styles/src/components/Layout';
import Footer from 'e84-website-styles/src/components/Footer';

import Header from 'components/Header';

const TemplateWrapper = ({ children }) => {

  return (
    <Layout>
      <Helmet>
        <title>CubeSat Data</title>
      </Helmet>

      <Header />

      <main className="default-view">
        { children }

        <Footer link_base_uri="https://www.element84.com" />
      </main>
    </Layout>
  );

};

TemplateWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default TemplateWrapper;
